@use "variables";

@font-face {
    font-family: Noxlock;
    src: url("../resources/fonts/Noxlock.otf") format("opentype");
}

@font-face {
    font-family: Swavao;
    src: url("../resources/fonts/Swavao.otf") format("opentype");
}

@font-face {
    font-family: Montserrat;
    src: url("../resources/fonts/Montserrat.ttf") format("opentype");
}

* {
    margin: 0;
    padding: 0;
    font-family: Montserrat;

    p,
    li,
    h4,
    .entry-content {
        font-size: 1.1rem;
    }
}

html,
#root {
    height: 100%;
}

#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-image: url("/resources/cube.svg");
    background-size: 10rem;
}

body {
    min-height: 100%;
    background: variables.$background-color;

    h1,
    h2,
    label {
        font-family: Noxlock;
        color: variables.$main-color-lighter;

        &::first-letter {
            color: variables.$accent-color;
        }
    }

    span.accented {
        color: variables.$accent-color;
        font-family: inherit;
    }
}

@keyframes loading {
    from {
        background: variables.$background-color-lighter;
    }

    to {
        background: variables.$background-color;
    }
}

.panel-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 0.5rem;
    margin: 0.5rem 0 1.5rem 0;

    .eye-catcher-container {
        align-self: stretch;
        margin: 0 0.5rem;

        video,
        iframe {
            width: 100%;
            height: 35rem;
            border-radius: 0.5rem;
            border: none;
            box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.4);
            animation: 1s infinite alternate loading;
        }

        video {
            object-fit: cover;
        }
    }

    .content {
        width: 100%;
        max-width: 80rem;
        display: flex;
        flex-direction: column;
        row-gap: 1.5rem;

        > .panel {
            margin: 0 1.5rem;
            padding: 2rem;
            background: variables.$background-color-dark;
            box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.4);
            border-radius: 1rem;
            color: white;

            &.over-eye-catcher {
                position: relative;
                top: -7rem;
                margin-bottom: -7rem;
            }

            h1 {
                margin-bottom: 0.5rem;
            }
        }
    }

    @media only screen and (max-width: 600px) {
        margin: 1rem 0;
        row-gap: 1rem;

        .content {
            row-gap: 1.25rem;
        }

        .eye-catcher-container {
            margin: 0 0.75rem;

            video,
            iframe {
                height: 20rem;
            }
        }

        .content > .panel {
            margin: 0 0.75rem;
            padding: 1rem;
            border-radius: 0.5rem;

            &.over-eye-catcher {
                top: unset;
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }
}


@media only screen and (min-width: 850px) {
    .mobile-el {
        display: none;
    }
}